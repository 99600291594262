




















































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";

export default Vue.extend({
  data() {
    return {
      items: [],
      totalItem: null,
      currentPackageId: "",
      currentDate: "",
      loading: false,
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: "Ngày về VN", value: "truck_vn_time" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Chi phí đơn", value: "cost" },
        { text: "Phí ủy thác", value: "fee_trust" },
        { text: "Phí dịch vụ", value: "fee_service" },
        { text: "Lợi nhuận thanh toán", value: "profit" },
        { text: "Tổng lợi nhuận", value: "package_profit" },
      ],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
    };
  },
  watch: {
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentDate) {
        return;
      }
      const m = moment(this.currentDate);

      this.loading = true;
      const options: any = {
        extra: {
          statistics: true,
          truck_statistics: true,
          liquidation_slip_approve_time: {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          },
        },
        filters: [],
        report: true,
      };
      if (this.currentPackageId.trim()) {
        options.filters.push({
          key: "package_id",
          operator: "match",
          value: this.currentPackageId.trim(),
        });
      }
      let { items } = await apiClient.packageGetAll(options);
      items = items.map((item) => {
        if (item.truck && item.truck_total_volume) {
          item.cost =
            (item.volume / item.truck_total_volume) *
            item.truck.export_fee_total;
        } else {
          item.cost = 0;
        }
        item.package_profit =
          item.fee_transport + item.fee_trust + item.profit - item.cost;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.fee_transport += item.fee_transport;
          totalItem.cost += item.cost;
          totalItem.fee_trust += item.fee_trust;
          totalItem.fee_service += item.fee_service;
          totalItem.profit += item.profit;
          totalItem.package_profit += item.package_profit;
          return totalItem;
        },
        {
          fee_transport: 0,
          cost: 0,
          fee_trust: 0,
          fee_service: 0,
          profit: 0,
          package_profit: 0,
        }
      );

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
  },
});
