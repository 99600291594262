var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Tìm đơn hàng"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initialize($event)}},model:{value:(_vm.currentPackageId),callback:function ($$v) {_vm.currentPackageId=$$v},expression:"currentPackageId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.timeModes,"label":"Chế độ thời gian"},model:{value:(_vm.timeMode),callback:function ($$v) {_vm.timeMode=$$v},expression:"timeMode"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Chọn tháng","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"type":"month"},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}})],1)],1)],1)],1),(_vm.currentDate)?_c('div',{staticClass:"mt-4"},[(_vm.totalItem)?_c('v-simple-table',{staticClass:"report-total blue lighten-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Tổng vận chuyển")]),_c('th',[_vm._v("Tổng chi phí đơn")]),_c('th',[_vm._v("Tổng phí ủy thác")]),_c('th',[_vm._v("Tổng phí dịch vụ")]),_c('th',[_vm._v("Tổng lợi nhuận thanh toán")]),_c('th',[_vm._v("Tổng lợi nhuận")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.fee_transport)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.cost)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.fee_trust)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.fee_service)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.profit)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.package_profit)))])])])]},proxy:true}],null,false,2009538457)}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-4 report-details",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","items-per-page":50,"footer-props":{
          'items-per-page-options': [50, 100, 150, 200, 250],
        },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.truck_vn_time",fn:function(ref){
        var item = ref.item;
return [(item.truck)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.truck.vn_time))+" ")]):_vm._e()]}},{key:"item.fee_transport",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.fee_transport))+" ")]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.cost))+" ")]}},{key:"item.fee_trust",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.fee_trust))+" ")]}},{key:"item.fee_service",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.fee_service))+" ")]}},{key:"item.profit",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.profit))+" ")]}},{key:"item.package_profit",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.package_profit))+" ")]}}],null,false,465329794)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }